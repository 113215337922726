// application/frontend/src/App.js
import React, { useEffect } from 'react';
//import { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Cancel from './pages/Cancel';
import Store from './pages/Store';
import Success from './pages/Success';
import LocationChooser from './pages/LocationChooser';

import CartProvider from './CartContext';
import CheckoutMbWay from './components/CheckoutMbWay';
import { LocationProvider } from './LocationContext';

import { backend } from './authConfig';

function App() {
  //const [status, setStatus] = useState(null);

  const fName = "App";
  const logPrefix = `<${fName}>`;
  console.log(`${logPrefix} backend.ping:`, backend.ping)

  const pingBackends = async () => {

    
    const fName = "App.pingBackend";
    const logPrefix = `<${fName}>`;

    // ping backend /api/ping
    fetch(`${backend.ping}`)
    .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.text();
    })
    .then((data) => {
        console.log(`${logPrefix} response:`, data);
        // If you parsed JSON: setStatus({ status_code: data.status_code });
    })
    .catch((error) => {
        console.error('Error pinging backend:', error);
    });

    // ping POST backend /api/v2/payrequest/ping
    fetch(`${backend.ping_post_payrequest}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      console.log(`${logPrefix} response:`, data);
    })
    .catch((error) => {
      console.error('Error pinging backend:', error);
    });
  
    // ping GET backend /api/v2/payrequest/ping
    fetch(`${backend.ping_get_payrequest}`, {
      method: 'GET',
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      console.log(`${logPrefix} response:`, data);
    })
    .catch((error) => {
      console.error('Error pinging backend:', error);
    });
  



  };

  useEffect(() => {
    pingBackends();
  }, []);

  // Check if status has been loaded
  //if (!status) {
  //  return <div>Loading...</div>;
  //}

  // Check if the system is under maintenance
  //if (status.status_code === 0) {
  //  console.log(`${logPrefix} status_code:`, status.status_code)
  //  return <div>O sistema está em manutenção. Tente mais tarde</div>;
  //} else 
  
  return (
    <CartProvider>
      <LocationProvider>
        <Container>
          <BrowserRouter>
          <Routes>
            <Route index element={<LocationChooser />} />
            <Route path="/store" element={<Store />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/location/:locationId" element={<Store />} />
            <Route path="/checkout-mbway/:locationId/:productNumber" element={<CheckoutMbWay />} />
          </Routes>
          </BrowserRouter>
        </Container>
      </LocationProvider>
    </CartProvider>
  );
}

export default App;
