//import { backend } from './authConfig';
import { app_configuration_data } from './authConfig';


function getLocationData() {
    // Calculate cache busting value that changes every time it is called
    const cacheBuster = Date.now(); // Current timestamp in milliseconds

    const url = `${app_configuration_data.url}?cb=${cacheBuster}`;
    
    return fetch(url)
        .then(response => response.json())
        .catch(error => console.error('Error fetching location data:', error));
}



function getProductsArray(locationId) {
    return getLocationData()
        .then(data => {
            // log only if running on localhost
            if (window.location.hostname === 'localhost') console.log("data: " + JSON.stringify(data));
            const products = data[locationId.toString()]; // convert locationId to string because keys in JSON are strings
            if (!products) {
                throw new Error(`No data for location ${locationId}`);
            }
            const productsArray = products.map(product => {
                const number = product.index;
                const title = product.type === 'room' ? `QUARTO` : `garagem`;
                const price = parseFloat(product.price);
                const capacity = product.capacity;
                return { 
                    unitId: product.unitId, 
                    number, 
                    title,
                    price,
                    utilitiesFees: product.utilitiesFees,
                    depositMonth: product.depositMonth,
                    depositUtilitiesFees: product.depositUtilitiesFees,
                    depositDamageFees: product.depositDamageFees,
                    depositTotal: product.depositTotal,
                    capacity
                };
            });
            return productsArray;
        })
        .catch(error => console.error(error));
}




function getProductData(number, locationId) {
    return getProductsArray(locationId)
        .then(productsArray => {
            const productData = productsArray.find(product => product.number === number);
            if (productData === undefined) {
                console.log("Product data does not exist for number: " + number);
                return undefined;
            }
            return productData;
        })
        .catch(error => console.error(error));
}

export { 
    getProductsArray,
    getProductData 
};
