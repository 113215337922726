// application/frontend/src/pages/Store.js
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getProductsArray } from '../ProductsStore';
import ProductCard from '../components/ProductCard';
import { useLocationContext } from '../LocationContext';

function Store() {
  const fName = 'Store';
  const logPrefix = `<${fName}>`;
  console.log(logPrefix, ' Start');
  
  const { locationId } = useParams();
  const { setLocationId } = useLocationContext(); 

  const [productsArray, setProductsArray] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('mbway');

  const navigate = useNavigate();

  const staticData = {
    1: { name: 'Rua Travessa 7' }
  };
  const ENABLE_ADDITIONAL_PAYMENT_METHODS = false;

  console.log(logPrefix, 'locationId:', locationId);

  useEffect(() => {
    setLocationId(locationId);
    getProductsArray(locationId)
      .then((products) => setProductsArray(products))
      .catch((error) => console.error(error));
  }, [locationId, setLocationId]);
  console.log(logPrefix, ' End');

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleCartClick = (productNumber) => {
    console.log(logPrefix, 'handleCartClick productNumber:', productNumber);
    if (paymentMethod === 'mbway') {
      navigate(`/checkout-mbway/${locationId}/${productNumber}`);
    } else {
      
    }
  };
  
  

  if (productsArray.length === 0) {
    return  <div>
              <h3>A carregar...</h3>
            </div>;
  }

  return (
    <>
      <h1 align="center" className="p-1 white-text">
        {staticData[locationId].name}
      </h1>
      <h4 className="p-1 white-text">Método de pagamento:</h4>
      <Row className="justify-content-center mb-3">
        <Col xs={12}>
          <Col className="d-flex justify-content-between">
          <ToggleButtonGroup
        type="radio"
        name="paymentMethod"
        value={paymentMethod}
        onChange={handlePaymentMethodChange}
        className="toggle-btn-group"
      >
        <ToggleButton
          id="mbway"
          value="mbway"
          className={`toggle-btn ${paymentMethod === 'mbway' ? 'active' : ''}`}
        >
          MBWay
        </ToggleButton>
        {ENABLE_ADDITIONAL_PAYMENT_METHODS && (
          <>
            <ToggleButton
              id="credit-card"
              value="credit-card"
              className={`toggle-btn ${paymentMethod === 'credit-card' ? 'active' : ''}`}
            >
              Cartão Crédito
            </ToggleButton>
            <ToggleButton
              id="apple-pay"
              value="apple-pay"
              className={`toggle-btn ${paymentMethod === 'apple-pay' ? 'active' : ''}`}
            >
              Apple Pay
            </ToggleButton>
            <ToggleButton
              id="google-pay"
              value="google-pay"
              className={`toggle-btn ${paymentMethod === 'google-pay' ? 'active' : ''}`}
            >
              Google Pay
            </ToggleButton>
            <ToggleButton
              id="others"
              value="others"
              className={`toggle-btn ${paymentMethod === 'others' ? 'active' : ''}`}
            >
              ...
            </ToggleButton>
          </>
        )}
      </ToggleButtonGroup>

        </Col>
        </Col>
      </Row>
      <Row className="g-2">
        {productsArray.map((product, idx) => (
          <Col xs={12} align="center" key={idx}>
            <ProductCard product={product} onCartClick={() => handleCartClick(product.number)} />
          </Col>
        ))}
      </Row>
    </>
  );
    
  
}

export default Store;
