// src/pages/LocationChooser.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const LocationChooserPage = () => {
  const navigate = useNavigate();

  const handleLocationSelection = (locationId) => {
    navigate(`/location/${locationId}`);
  };

  return (
    <div className="location-chooser">
      <h1>Escolha o local</h1>
      <div>
        <button onClick={() => handleLocationSelection(1)}>Rua Travessa 7</button>
      </div>
    </div>
  );
};

export default LocationChooserPage;
