// application/frontend/src/components/CheckOutMbWayModal.jsx
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
//import { useLocationContext } from '../LocationContext';
//import { useNavigate } from 'react-router-dom';
import './EllipsisAnimation.css';

const paymentStatusMapping = {
  "000": "Transação financeira concluída com sucesso",
  "020": "Transação financeira cancelada pelo utilizador",
  "023": "Transação financeira devolvida pelo Comerciante",
  "048": "Transação financeira cancelada pelo Comerciante",
  "100": "A operação não pôde ser concluída",
  "104": "Operação financeira não permitida",
  "111": "O formato do número de telemóvel não estava correto",
  "113": "O número de telemóvel usado como identificador não foi encontrado",
  "122": "Operação recusada ao utilizador",
  "123": "Transação financeira não encontrada",
  "125": "Operação recusada ao utilizador",
};


const CheckoutMbWayModal = ({
  showModal,
  handleCloseModal,
  handleCloseModalAndNavigate,
  paymentRequested,
  paymentConfirmed,
  paymentFailed,
  paymentStatus
}) => {
  //const { locationId } = useLocationContext();
  //const navigate = useNavigate();

  const handleOkButtonClick = () => {
    handleCloseModalAndNavigate();
    //navigate(`/location/${locationId}`);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Por favor aprove o pagamento na App MB WAY</h5>
  
        <h5 className={paymentRequested ? 'd-block' : 'd-none'}>
          Aguardando a confirmação do pagamento{' '}
          <span className={!paymentConfirmed && !paymentFailed ? 'ellipsis' : ''}></span>
          {paymentConfirmed && (
            <>
              <FontAwesomeIcon icon={faCheck} style={{ color: 'green', marginLeft: '8px' }} />
              <p>{paymentStatusMapping[paymentStatus]}</p>
            </>
          )}
        </h5>
  
        <h5 className={paymentConfirmed ? 'd-block' : 'd-none'}>
          Obrigado pela reserva. Irá receber um email com informação{' '}
          <span className={!paymentFailed ? 'ellipsis' : ''}></span>
        </h5>
  
        <h5 className={paymentFailed ? 'd-block' : 'd-none'}>
          O pagamento falhou ou expirou
          <p>{paymentStatusMapping[paymentStatus]}</p>
        </h5>
      </Modal.Body>
      {paymentConfirmed && (
        <Modal.Footer>
          <Button variant="primary" onClick={handleOkButtonClick}>
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CheckoutMbWayModal;
