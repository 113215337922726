import { Card, Row, Col } from 'react-bootstrap';

function ProductCard(props) {
  const { product } = props;

  return (
    <Card className="product-card m-2" style={{ boxShadow: '0 2px 4px rgba(0,0,0,.2)' }}>
      <Card.Body>
        <Row>
          <Col xs={12} md={6} className="text-left">
            <h5 className="mb-1">{product.title} - {product.unitId}</h5>
          </Col>
          <Col xs={12} md={6} className="d-flex flex-column align-items-end">
            <p className="w-100 font-weight-bold text-left" >Pago no início do contrato:</p>
            <div className="d-flex justify-content-between w-100">
              <span style={{ color: '#999' }}>Renda Mensal:</span>
              <strong>€{product.price}</strong>
            </div>
            <div className="d-flex justify-content-between w-100">
              <span style={{ color: '#999' }}>Despesas Mensais:</span>
              <strong>€{product.utilitiesFees}</strong>
            </div>
            <p className="w-100 font-weight-bold text-left mt-3">Pago agora:</p>
            <div className="d-flex justify-content-between w-100">
              <span style={{ color: '#999' }}>Caução Mês:</span>
              <strong>€{product.depositMonth}</strong>
            </div>
            <div className="d-flex justify-content-between w-100">
              <span style={{ color: '#999' }}>Caução Despesas:</span>
              <strong>€{product.depositUtilitiesFees}</strong>
            </div>
            <div className="d-flex justify-content-between w-100">
              <span style={{ color: '#999' }}>Caução Estragos:</span>
              <strong>€{product.depositDamageFees}</strong>
            </div>
            <div className="d-flex justify-content-between w-100 mt-3">
              <span >Total Reserva:</span>
              <strong>€{product.depositTotal}</strong>
            </div>
          </Col>
          <Col xs={12} className="text-right mt-3">
            <button
              className="btn btn-primary"
              onClick={() => props.onCartClick(product.id)}
            >
              <i className="fa fa-shopping-cart" aria-hidden="true"></i> Reservar
            </button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;