// application/frontend/src/components/CheckoutMbWayModal.jsx
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber as validatePhoneNumber } from 'libphonenumber-js'; 

// Application imports
import { getProductsArray } from '../ProductsStore';
import { backend } from '../authConfig';

import CheckoutMbWayModal from './CheckoutMbWayModal'; 

import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * useProduct: Custom hook to fetch and return the product based on the provided locationId and productNumber.
 *
 * @param {string} locationId - The location ID.
 * @param {string} productNumber - The product number.
 * @returns {Object} - The product object.
 */
function useProduct(locationId, productNumber) {
  const [product, setProduct] = useState(null);

  useEffect(() => {
    getProductsArray(locationId)
      .then((products) => {
        const selectedProduct = products.find(
          (product) => product.number === parseInt(productNumber)
        );
        setProduct(selectedProduct);
      })
      .catch((error) => console.error(error));
  }, [locationId, productNumber]);

  return product;
}

// Helper function to validate email
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Helper function to validate Portuguese VAT tax ID (NIF)
function validateNif(nif) {
  const re = /^\d{9}$/;
  if (!re.test(nif)) {
    return false;
  }

  // Accept the default generic NIF
  if (nif === "999999990") {
    return true;
  }

  // Check if the first digit corresponds to valid mode prefixes
  const firstDigit = nif.slice(0, 2);
  const validPrefixes = ['1', '2', '3', '45', '5', '6', '70', '74', '75', '71', '72', '77', '78', '90', '91', '98', '99'];
  
  // We need to check for the two-digit prefixes first
  if (!validPrefixes.includes(firstDigit) && !validPrefixes.includes(nif[0])) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += nif[i] * (9 - i);
  }

  const remainder = sum % 11;
  const checkDigit = remainder < 2 ? 0 : 11 - remainder;

  return nif[8] === checkDigit.toString();
}



/**
 * CheckoutMbWay component: Provides the MBWay checkout form to the user and handles
 * the payment request, polling for payment confirmation, and machine activation.
 */
function CheckoutMbWay() {
  
  // Form fields state
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [nif, setNif] = useState('');
  const [isValidNif, setIsValidNif] = useState(false);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail ] = useState(false);
  const [ name, setName] = useState('');

  const [showModal, setShowModal] = useState(false);

  // Process state
  const [paymentRequested, setPaymentRequested] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  
  const [machineActivated, setMachineActivated] = useState(false);

  const navigate = useNavigate();

  const { locationId, productNumber } = useParams();
  const product = useProduct(locationId, productNumber);

  useEffect(() => {
    setIsValidPhoneNumber(validatePhoneNumber(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  useEffect(() => {
    setIsValidNif(validateNif(nif));
  }, [nif]);

  /**
   * submitPaymentRequest: Sends the MBWay payment request to the server.
   *
   * @returns {Promise<string>} - A promise that resolves to the payment ID.
   * @throws {Error} - If the payment request fails.
   */
  const submitPaymentRequest = async () => {
    const formattedLocationId = locationId.toString().padStart(2, '0');
    const formattedProductNumber = productNumber.toString().padStart(2, '0');
  
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const countryCode = parsedPhoneNumber.countryCallingCode;
    const nationalNumber = parsedPhoneNumber.nationalNumber;
    const formattedPhoneNumber = `${countryCode}#${nationalNumber}`;
    console.log('Country code:', countryCode);
    console.log('Phone number:', nationalNumber);
    console.log('Formatted phone number:', formattedPhoneNumber);
  
    const params = {
      mc: `P${formattedLocationId}-${formattedProductNumber}`,
      phone_number: formattedPhoneNumber,
      nif: nif,
      email: email,
      name: name
    };
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    };
  
    try {
      const response = await fetch(`${backend.url}v2/payrequest/mbway`, requestOptions);
      setPaymentRequested(true);
  
      if (!response.ok) {
        throw new Error('Failed to create MBWay payment');
      }
      const responseData = await response.json();
      const paymentId = responseData.IdPedido || responseData.payment_id;
      console.log('Payment ID:', paymentId);
      return paymentId
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  /**
   * handleSubmit: Handles the form submission and initiates the payment request.
   *
   * @param {Event} e - The event object.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form...');

    // Reset the payment and machine activation states
    setPaymentRequested(false);
    setPaymentConfirmed(false);
    setPaymentFailed(false);
    setPaymentStatus(null);
    setMachineActivated(false);

    let invalidReasons = [];
    if (phoneNumber && !isValidPhoneNumber) {
      invalidReasons.push("Número de telefone inválido");
    }
    if (nif && !isValidNif) {
      invalidReasons.push("NIF inválido");
    }
    if (email && !isValidEmail) {
      invalidReasons.push("Email inválido");
    }
  
    if (invalidReasons.length > 0) {
      console.log('Invalid form due to the following reasons:', invalidReasons.join(', '));
      return;
    }
    setShowModal(true);
  
    try {
        const paymentId = await submitPaymentRequest();
        pollPaymentConfirmation(paymentId);
    } catch (error) {
      console.error(error);
      // Handle error, e.g., show an error message to the user
    }
};


  /**
   * pollPaymentConfirmation: Polls the server for the MBWay payment confirmation.
   *
   * @param {string} paymentId - The payment ID to check the confirmation for.
   */
  const pollPaymentConfirmation = async (paymentId) => {
    console.log("Polling payment confirmation for payment ID:", paymentId);
  
    const maxRetries = 12; // Poll for 2 minutes (12 times * 10 seconds)
    let retries = 0;
  
    const paymentStatusMapping = {
      "000": "Financial transaction completed successfully",
      "020": "Financial transaction cancelled by the user",
      "023": "Financial transaction returned by the Merchant",
      "048": "Financial transaction cancelled by the Merchant",
      "100": "The operation could not be completed",
      "104": "Financial operation not allowed",
      "111": "The format of the mobile number was not in the correct format",
      "113": "The mobile number used as an identifier was not found",
      "122": "Operation refused to the user",
      "123": "Financial transaction not found",
      "125": "Operation refused to the user",
    };
  
    const poll = async () => {
      if (retries >= maxRetries) {
        console.log("Polling stopped after reaching the maximum retries");
        setPaymentFailed(true);
        return;
      }
  
      try {
        const queryParams = new URLSearchParams({ payment_id: paymentId }).toString();
        const response = await fetch(`${backend.url}v2/payrequest/mbway?${queryParams}`);
  
        if (!response.ok) {
          throw new Error('Failed to poll MBWay payment');
        }
  
        const responseData = await response.json();
        const paymentStatus = responseData.Estado;
        console.log("responseData", responseData);
  
        if (paymentStatus !== "123") {
          if (paymentStatus === "000") {
            console.log("Payment status changed:", responseData);
            setPaymentConfirmed(true);
            pollMachineActivation();
          } else if (paymentStatusMapping[paymentStatus]) {
            console.log("Payment status changed:", paymentStatusMapping[paymentStatus]);
            setPaymentFailed(true);
          }
        } else {
          retries++;
          setTimeout(poll, 10000); // Poll every 10 seconds
        }
        setPaymentStatus(paymentStatus);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    poll();
  };
  
  
  /**
   * pollMachineActivation: Polls the server for the machine activation.
   */
  const pollMachineActivation = async () => {
    setTimeout(() => {
      setMachineActivated(true);
    }, 5000);
  };
  
  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card className="product-card">
        <Card.Body>
          <Card.Title>Reserva com MbWay</Card.Title>
          <Card.Subtitle className="mb-2">{product.title} {product.number}</Card.Subtitle>
          <Card.Text>Total do depósito - €{product.depositTotal}</Card.Text>
          <div style={{ fontSize: '0.85rem', color: 'grey', marginBottom: '20px' }}>
            <Card.Text style={{ marginBottom: '2px' }}>Caução Mês - €{product.depositMonth}</Card.Text>
            <Card.Text style={{ marginBottom: '2px' }}>Caução Despesas - €{product.depositUtilitiesFees}</Card.Text>
            <Card.Text style={{ marginBottom: '2px' }}>Caução Estragos - €{product.depositDamageFees}</Card.Text>
          </div>
          <Form onSubmit={handleSubmit}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Form.Group controlId="phone">
                  <Form.Label>Número de telemóvel (obrigatório):</Form.Label>
                  <PhoneInput
                      international
                      placeholder="Introduza o seu número de telefone"
                      defaultCountry="PT"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      className="phone-input"
                      required
                      />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Form.Group controlId="nif">
                <Form.Label>NIF (obrigatório)</Form.Label>
                <Form.Control
                  type="text"
                  value={nif}
                  onChange={(e) => setNif(e.target.value)}
                  onBlur={() => setIsValidNif(validateNif(nif))}
                />
                {nif && !isValidNif && (
                  <Form.Text className="text-danger">
                    Introduza um NIF válido.
                  </Form.Text>
                )}
              </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
              <Form.Group controlId="email">
              <Form.Label>Email (obrigatório)</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => setIsValidEmail(validateEmail(email))}
              />
              {email && !isValidEmail && (
                <Form.Text className="text-danger">
                  Por favor introduza um email válido.
                </Form.Text>
              )}
            </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Nome (obrigatório):</Form.Label>
                  <Form.Control
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={
                !isValidPhoneNumber ||
                (!nif || !isValidNif) ||
                (!email || !isValidEmail) ||
                !name
              }>Finalizar
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {/* Modal */}
      {showModal && (
        <CheckoutMbWayModal
          showModal={showModal}
          handleCloseModal={() => {
            setShowModal(false);
            if (machineActivated) {
              navigate('/');
            }
          }}
          handleCloseModalAndNavigate={() => {
            setShowModal(false);
            navigate(`/location/${locationId}`);
          }}
          paymentRequested={paymentRequested}
          paymentConfirmed={paymentConfirmed}
          paymentFailed={paymentFailed}
          machineActivated={machineActivated}
          paymentStatus={paymentStatus}
        />
      )}
    </>
  );
}

export default CheckoutMbWay;
